div.hero-banner
{
    
    width:100%;
    background-color: beige;
    background-size:cover;
    background-repeat:no-repeat;
    aspect-ratio: 1920/920;
    overflow: hidden;
}
div.h1_bg
{
    background-color: #3D3D3D;

    position: absolute;
    width: 35%;
    aspect-ratio: 748/920;
  
    height: 100%;
    display: flex;
    clip-path: polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%);
    z-index: 2;
    transition: all ease-in-out 0.7s;
    overflow: hidden;
    
}
div.h1_bg:hover 
{
    
    clip-path: polygon(0% 0%, 85% 0%, 100% 100%, 0% 100%);
    width: 35.5%;
}
div.h1_bg img 
{
    width: 100%;
    height: auto;
    margin-top: auto;
}

.hero_content_block_parent {
    position: relative;
}
div.h2_bg
{
    background-color: #212121;
    position: absolute;
    width: 52.5%;
    aspect-ratio: 1070 / 920;
    margin-left: 23.75%;
    height: 100%;
    display: flex;
    z-index: 1;
    overflow: hidden;
    transition:all ease 0.7s;
}
.h2_bg:hover{
    transform: scale(1.01);
}
.hero_content_block_parent .h2_bg:hover ~ .h1_bg {
    clip-path: polygon(0% 0%, 70% 0%, 100% 100%, 0% 100%);
}
.hero_content_block_parent .h2_bg:hover ~ .h3_bg {
    clip-path: polygon(30% 0%, 100% 0%, 100% 100%, 0% 100%);
}
div.h2_bg img 
{
    width: 120%;
    height: auto;
    margin-top: auto;
}

div.h3_bg
{
    overflow: hidden;
    display: flex;
    background-color: #3D3D3D;
    
    position: absolute;
    width: 35%;
    aspect-ratio: 748/920;
    margin-left: 65%;
   
    height: 100%;
    clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
    z-index: 2;
    transition: all ease-in-out 0.7s;
}
div.h3_bg:hover 
{
    
    clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
    width: 35.5%;
}
div.h3_bg img 
{
    width: 100%;
    height: auto;
    margin-top: auto;
}
div.hero_content_block_parent
{
    width: 100%;
    height: 100%;
    display: flex
  
}

@media only screen and (max-width: 1024px)
{
    div.pagination_home_controls {

        width: 70%;
        margin-top: 30%;
        margin-left: 5%;
    }

    div.hero-banner {
        aspect-ratio: 4 / 4;
    }
    div.h1_bg
    {
        clip-path: polygon(0% 0%, 63% 0%, 100% 100%, 0% 100%);
    }
    div.h3_bg
    {
        clip-path: polygon(37% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
   
}

div.main_header
{
    width:100%;
    height:auto;
 
}
div.logo_container
{
    margin-top:auto;
    margin-bottom:auto;
    transition:all ease 0.8s;
}
div.logo_container img 
{
    width: 100%;
    height: auto;
    transition:all ease 0.8s;
}
div.nav_container
{
    margin-top: auto;
    margin-bottom: auto;
    width: 70%;
    margin-right: 5%;
    justify-content: space-between;
    margin-left: 5%;
    transition:all ease 0.8s;
}
div.millhouse_logo
{
    margin-left:auto;
}
div.nav_ele
{
 
}
div.nav_ele a
{
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    color:unset;
}
button.nav_ele_btn
{
    border:1.8px solid #FA4616;
    background-color:unset;
}
header.main_header
{
    position: fixed;
    top: 0;
    width: 90%;
    padding-top: 0.8%;
    padding-bottom: 0.8%;
    transition:all ease 0.8s;
    color:#E3E3E3;
    z-index: 9;
}
header.main_header.active_header
{
    color:#000;
    padding-top: 0.2%;
    padding-bottom:0.2%;
    box-shadow: 0 2px 9px 0 rgb(0 0 0 / 9%);
}
header.main_header div.nav_container
{

    width: 85%;
    margin-right: 0%;
}
header.main_header.active_header div.nav_container
{

    width: 85%;
}

header.main_header button.nav_ele_btn
{
    border: 1.8px solid #FA4616;
    background-color: unset;
    color: #E3E3E3;
    border-radius: 20vw;
    padding:4% 25%;
    white-space: nowrap;
    transition:all ease 0.8s;
    cursor: pointer;
}
button.nav_ele_btn p 
{
    
}
header.main_header.active_header button.nav_ele_btn
{
    background-color: #FA4616;
}
div.nav_eleIco
{
    width: 18%;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5%;
}
div.nav_ele_text
{
    margin-top: auto;
    margin-bottom: auto;
    white-space: nowrap;
    cursor: pointer;
}
 img.nav_eleIco 
{
    width:25px;
    height:auto;
    margin-left:8%;
   
}
.main_header .site_logo
{
    width:8%;
    cursor: pointer;
}
.main_header .millhouse_logo
{
    width:12%;
}
.main_header.active_header .site_logo
{
    width:5%;
}
.main_header.active_header .millhouse_logo
{
    width:10%;
}

div.hero_content_block 
{
    padding-top: 16vw;
    width: 45%;
    text-align: center; 
}
div.rightBanner
{
    width:15%;
    margin-left:auto;
    margin-top:auto;
    margin-bottom:0;
    
}
div.socialMediaPanel
{
    width: 15%;
    margin-left: auto;
}
div.socialMediaPanel div.social_media_panel
{
    width:100%;
    height:auto;
}
div.socialMediaPanel div.social_media_panel div.social_media_ele
{
    width:100%;
    height:auto;
    margin-bottom: 1.2vw;
}
div.socialMediaPanel div.social_media_panel div.social_media_ele img 
{
    width:100%;
    height:auto;
}
div.heroLogo_clckable
{
    width: 60%;
    height: auto;
    margin-left: auto;
}
div.heroLogo_clckable div.cricclubs_logo
{
    width:100%;
    height:auto;
}
div.cricclubs_logo img 
{
    width:100%;
    height:auto;
}
div.HeroContent_title {
    margin-bottom: 3%;
}
div.HeroContent_copy
{
    margin:2% 0% 2% 0%;
}
div.HeroContent_title h1 
{
    margin:1% 0% 1% 0%;
    letter-spacing: 0.1rem;
}
div.HeroContent_copy p 
{
    margin:1% 0% 1% 0%;
}
div.HeroContent_btn
{
    margin:5% 0% 0% 0%;
}
div.HeroContent_btn button 
{
    color:#fff;
    background-color: #FA4616;
    border: 1.8px solid #FA4616;
    padding:1.6% 5% 1.6% 5%;
    border-radius: 20vw;
    cursor:pointer; 
    transition:all ease 0.8s;
}
div.HeroContent_btn button:hover 
{
    background-color:#fff;
    color:#FA4616;
}
div.MDE_parent div.MDE_container
{
    width: 22%;

    padding: 1.2% 4% 1.2% 4%;
    border-radius: 18px;
    margin-top:-3%;
    transition: all ease 0.6s;
    
}

div.MDE_container div.match_date_ico
{
    width:4.8%;
    margin-left: auto;
    margin-right: 2%;
    margin-top: auto;
    
}
div.match_date_ico img 
{
    width:100%;
    height:auto;
}
div.MDE_parent
{
    justify-content: space-between;
    margin-bottom: 3%;
}

div.MDE_container div.match_loc_ico
{
    width:5%;
    margin-left:auto;
    margin-right: 2%;
}
div.MDE_container div.match_loc_info
{
    margin-right:auto;
    margin-top: auto;
}
div.match_loc_ico img 
{
    width:100%;
    height:auto;
}
div.MDE_container div.match_date 
{
    margin-left:auto;
    margin-right:auto;
}

div.MDE_container  div.match_date_info
{
    margin-right:auto;
    margin-top: auto;
}
div.UPG_container
{
    padding-bottom:5%;
    position: relative;
    z-index: 9;
    
}
div.match_league
{
    text-align: center;
}
div.vs_ico
{
    width:40%;
}
div.vs_ico img 
{
    width: 100%;
    height:auto;
    border-radius: 20vw;
}
div.match_vs_icos 
{
    justify-content: space-between;
    margin-top: 4%;
    margin-bottom: 4%;
}
div.vs_content
{
    margin-top:auto;
    margin-bottom:auto;
}

div.view_all_btn
{
    text-align: center;
}
div.view_all_btn button 
{
    padding: 0.8% 3% 0.8% 3%;
    background-color: transparent;
    border: 1.8px solid #FA4616;
    cursor: pointer;
    transition: all ease 0.8s;
    color: #FA4616;
    border-radius: 20vw;
}
div.view_all_btn button:hover 
{
    background-color:#FA4616;
    color:#fff;
}
div.LT_parent {
    padding-bottom: 10%;
}   


div.LT_title
{
    border-left: 1vw solid #FA4616;
    padding-left: 0.8%;
    margin-bottom: 3%;
}
div.LT_container 
{
    margin:2% 0%;
    background-color:#161616;
    min-height: 200px;
    padding:0% 2% 2% 2%;
    justify-content: space-between;
    border-radius: 18px;

}
div.LT_container div.LT_league_standings
{
    width: 23%;
    
}
div.LT_league_logo
{
    width: 30%;
    /* min-height: 22.8vw; */
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 5% 0% 1% 0%;
    border-radius: 18px 18px 0px 0px;
    margin-top: -1%;
    flex-direction: column;
    
}
div.LT_league_info {
    width: 15%;
    margin-top: 2.5%;
    flex-direction: column;
    justify-content: space-around;
}
div.league_ico
{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 115%;
}
div.league_btn
{
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
div.league_btn button 
{
    border: 1.8px solid #FA4616;
    background-color: #FA4616;
    transition: all ease 0.8s;
    cursor: pointer;
    width: 90%;
    margin-left: 5%;
    border-radius: 20vw;
    padding:0.8vw 2vw;
}
div.league_ico img 
{
    width:100%;
    height:auto;
}
div.match_info_ele
{
    text-align: center;
    margin:6% 0%;
}
div.LT_league_standings
{
    
    background-color: #414141;
    margin-top: 2%;

    border-radius: 18px;

}
div.standings_title
{
    padding: 4% 5% 4% 5%;
    width: 90%;
    background-color: #494949;
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
}
div.SListing_header
{
    padding-left:5%;   
    padding-top:2%;
    padding-bottom:2%;
    border-bottom:2px solid #494949;
}
div.SListing_team_ele
{
    padding-left:5%;   
    padding-top:2%;
    padding-bottom:2%;
    border-bottom:2px solid #494949;
}
div.SListing_team_ele:last-child
{
    border-bottom:unset;
    padding-bottom:4%;
}
div.SListing_name
{
    width:80%;
}
div.SListing_win
{
    width:10%;
    margin-top: auto;
    margin-bottom: auto;
}
div.SListing_lose
{
    width:10%;
    margin-top: auto;
    margin-bottom: auto;
}
div.team_ico
{
    width: 13%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 6%;
}
div.team_ico img 
{
    width:100%;
    height:auto;
    border-radius: 20vw;
}
div.team_name
{
    margin-top:auto;
    margin-bottom:auto;
}

div.LT_container div.LT_fixtures_results_tabs_parent
{
    width: 25%;
    margin-top: 2%;
}

div.LT_fixtures_results_tabs_parent .react-tabs__tab-list
{
    border-bottom: unset;
    margin: 0 0 10px;
    padding: 0;
    border-radius: 20vw;
    background-color: #414141;
    padding: 3.5% 0%;
}
div.LT_fixtures_results_tabs_parent .react-tabs__tab:focus:after
{
    height: 0px;
}
div.LT_fixtures_results_tabs_parent .react-tabs__tab--selected
{
    background:unset;
    border:unset;
    color:#FA4616;
}

div.LT_fixtures_results_tabs_parent  .react-tabs__tab
{
    width:49%;
   
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    text-align: center;
    cursor: pointer;
    padding:0;
    border-radius: 0;
}
div.LT_fixtures_results_tabs_parent  .react-tabs__tab:first-child
{
    border-right: 2px solid #fff;
}

div.MXE_ele
{
    background-color: #414141;
    padding: 0%;
    border: 1.6px solid #fff;
    border-radius: 18px;
    margin-bottom: 2.5%;
}
div.MXE_ele:last-child
{
    margin-bottom: 0%;
}
div.oppo_team
{
    width: 34%;
    padding: 2% 0% 2% 4%;
}
div.oppo_vs_text
{
    width:20%;
    margin-top:auto;
    margin-bottom: auto;
}
div.oppo_team_ico
{
    width: 55%;
    height: auto;
    margin-left: 4%;
    margin-right: auto;
}
div.oppo_team_ico img 
{
    width:100%;
    height:auto;
    border-radius: 20vw;
    background-color:#fff;
}
div.oppo_team_name
{
    text-align: center;
}

div.MXE_ele div.match_location
{
    width:42%;
}
div.MXE_ele div.match_loc_ico
{
    width: 16%;
    height: auto;
    margin-right: 5%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5%;
}
div.MXE_ele div.match_loc_info
{
    margin-top: auto;
    margin-bottom: auto;
    color: #E3E3E3;
    width: 70%;
    margin-right: 4%;
    
}


div.MXE_ele div.match_date
{
    width: 18%;
    border: 1.6px solid #fff;
    border-radius: 18px;
    border-top: none;
    border-bottom: none;
    padding: 1.4% 1%;
}
div.MXE_ele div.match_date_ico
{
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
}
div.MXE_ele div.match_date_info
{
    text-align: center;
    color:#E3E3E3;
}
div.fixtures_container
{
    flex-direction: column;
    justify-content: space-between;
}

div.MRE_ele
{
    background-color: #414141;
    border-radius: 18px;
    border:1.6px solid #fff;
    margin-bottom: 2.5%;
}
div.MRE_ele:last-child 
{
    margin-bottom:0%;
}
div.main_teamInfo_parent
{
    width: 76%;
    padding: 3% 2%;
}
div.teamInfo_parent
{
    width:100%;
}
div.result_status
{
    width:100%;
}
div.teamInfo_parent
{
    justify-content: space-between;
}
div.team_info
{
    width:48%;
}
div.team_info_logo
{
    width:50%;
}
div.team_info_logo img 
{
    width: 100%;
    height:auto;
    border-radius: 20vw;
}
div.score_board
{
    width:45%;
}

div.won_team_info div.score_board
{
    color: #fff;
    text-align: right;
    padding-right: 5%;
    margin-top: auto;
    margin-bottom: auto;
}

div.lost_team_info div.score_board
{
    color:#CACACA;
 
    text-align: left;
    padding-left: 5%;
    margin-top: auto;
    margin-bottom: auto;
}
div.result_status
{
    text-align: center;
}



div.MRE_ele div.match_date
{
    width: 18%;
    border: 1.6px solid #fff;
    border-radius: 18px;
    border-top: none;
    border-bottom: none;
    padding: 1.4% 1%;
}
div.MRE_ele div.match_date_ico
{
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
}
div.MRE_ele div.match_date_info
{
    text-align: center;
    color:#E3E3E3;
}


section.LG_container
{
  
}

div.NMC_container
{
    position: absolute;
    
    justify-content: space-around;
    width:100%;
    height: 100%;
    
}
div.NMC_title
{
    text-align: center;
  
}
div.NMC_container div.counter 
{
    width:60%;
    margin-left: auto;
    margin-right:auto;
    margin-bottom:5%;
}
div.counter_ele
{
    width:25%;
    text-align: center;
    color:#E3E3E3;
}
div.counter_num
{
    font-size: 7rem;
    line-height: 7.2rem;
}
div.NMC_container
{
    flex-direction: column;

}
div.NMC_container div.common_buttonHolder
{
    text-align: center;
    margin-bottom: auto;
}
div.NMC_title
{
    margin-bottom:3%;
    margin-top: auto;
}
div.NMC_container div.common_buttonHolder button 
{
    color: #fff;
    border: 1.8px solid #FA4616;
    background-color: #FA4616;
    padding: 0.8% 2.5% 0.8% 2.5%;
    transition: all ease 0.8s;
    border-radius: 20vw;
    cursor: pointer;
    
}
section.MS_info_container
{

    padding-top:6%;
}
div.NMC_container div.common_buttonHolder button:hover 
{
    background-color:white;
    color:#FA4616;
}
div.MS_parent
{
    
}
div.bg_eagle_logo
{
    width: 38%;
    margin-right: 12%;
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 18.6vw;
      
}
div.MS_logo_content_parent
{
    padding-top:3%;
}
div.bg_eagle_logo img 
{
    width:100%;
    height:auto;
}
div.player_content
{
    width:50%;
    padding-bottom: 5%;
    min-height: 20rem;
}
div.player_name
{
    margin-bottom:2%;
}
div.player_bio
{
    margin-top: 5%;
    padding-right: 30%;
}
div.slider_container
{
    width:50%;
    padding-top:1%;
  
}
div.player_match_creds
{
    width: 40%;
    padding:3% 10% 3% 0%;
    justify-content: space-between;
    background-color: #161616;
    z-index: 8;
    
}
div.player_match_creds div.creds_ele
{
    margin-top: auto;
    margin-bottom: auto;
}
div.creds_ele
{
    text-align: center;
    background-color: #000;
    padding: 4% 5%;
    border-radius: 16px;
    height: fit-content;
    min-width: 4vw;
}
section.MS_slider_parent
{
    background-color: #161616;
    
    
}
div.carousel 
{
    height: 100%;
   
}
div.flickity-viewport
{
   
}
div:focus-visible {
    outline: unset;
}
div.slider_ele 
{
    width:30%;
    margin-right: 40%;  
    transition:all ease 0.8s;
}
div.slider_ele img 
{
    width:100%;
    height:auto;
    transition:all ease 0.8s;
}
div.slider_paginationBar
{
    width: 8%;
    position: absolute;
    justify-content: space-between;
    left: 15%;
    z-index: 7;
    margin-top: -4%;
}
div.slider_pag_ele
{
    width: 36%;
    z-index: 8;
    cursor: pointer;
}


div.slider_pag_ele img 
{
    width:100%;
    height:auto;
    
}
div.slider_pag_ele.slider_pag_prev_btn img 
{
    transform: rotate(180deg);
}
div.slider_ele.is-selected img 
{
    width: 210%;
    margin-top: -109%;
    margin-left: -109%;
}
button.flickity-button 
{
    position:absolute;
}
section.MS_total_container
{
    overflow: hidden;
}
section.Insta_LT_container
{
    min-height: 6vw;
   
}
div.logo_container.logo_center
{
    margin-left: auto;
    margin-right:auto;
    margin-top:unset;
    margin-bottom: 2%;
}
div.LG_parent
{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    row-gap: 0;
    column-gap: 8vw;
    padding-top: 4%;
    padding-bottom: 4%;
   
}
div.LG_parentParent
{
    background-color: #F1F3F6;
}
div.LG_parent2
{
    padding-top: 4%;
    padding-bottom: 4%;
}

div.LG_ele
{
    width: 100%;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
}
div.LG_parent2 div.LG_ele
{
    width:25%;
    margin-left:auto;
    margin-right:auto;
}
div.LG_ele img 
{
    width: 100%;
    height: auto;
}

section.footer_parent
{
    background-color: #160040;
    color:#E3E3E3;
    padding-top:2%;
    padding-bottom: 2%;
    text-align: center;
}
div.contact_info_title
{
    margin-bottom:1%;
}



/*  CALENDAR PAGE CSS */ 
section.UMP_container
{
   
    /* min-height: 30vw; */
    padding-top: 9%;
    padding-bottom: 4%;

}

div.UMP_match_ele div.MDE_container
{
    width: 92%;
    padding: 2.6% 4% 2.6% 4%;
    border-radius: 18px;
    margin-top: 0;
    transition: all ease 0.6s;
    cursor: pointer;
    color:#E3E3E3;
}
div.UMP_match_ele
{
    width: 30%;
    margin-right: 10%;
}
div.UMP_timer 
{
    width:100%;
    margin-top: auto;
    margin-bottom: auto;
}
div.UMP_timer div.counter 
{
    width:100%;
    margin-left: auto;
    margin-right:auto;
    margin-bottom:5%;
}
section.calendar_container
{
   
}
button.customCale_ele
{
    background-color: #161616;
    color: #fff;
    padding: 0% 0%;
    border-radius: 10px;
    border: unset;
    background-color: #161616;
    color: #fff;
    flex: unset !important;
    width: 14%;
    margin-bottom: 0.25%;
    min-height: 6.4vw;
}
div.calendar_holder
{
    padding-bottom:5%;
}
div.react-calendar__month-view__weekdays
{
    display: none !important;
}
div.react-calendar__navigation
{

    width: 96%;
    background-color: #000;
    display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      -webkit-box-direction: normal;
      -moz-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    justify-content: space-between;
    justify-content: space-between;
    padding: 2% 2%;
    border-radius: 18px;
    margin-bottom: 1%;
}
button.react-calendar__navigation__label
{
    width: 50%;
    flex-grow: unset !important;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-style: normal; 
    font-size:2.4rem;
    line-height: 2.6rem;
    background-color: #000;
    color: white;
    border:unset;
    cursor: pointer;
}
button.react-calendar__navigation__arrow
{
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-style: normal; 
    font-size: 2.2rem;
    line-height: 2.2rem;
    border-radius: 20vw;
    background-color: #000;
    border: unset;
    color: white;
    
    cursor: pointer;
}
button.customCale_ele abbr 
{
    display: none;
}
div.calendar_ele_date
{
    text-align: left;
}
div.calendar_ele_location
{
    text-align: left;
}
div.react-calendar__month-view__days
{
    justify-content: space-between;
}
div.caledar_ele_logo
{
    width:45%;
    height:auto;
}
div.calendar_ele_vsText
{
    text-align: right;
}
div.caledar_ele_logo img 
{
    width:100%;
    height:auto;
}
img.caledar_ele_logo
{
    width: 50%;
    height: auto;
    float: right;
    margin-left: 3%;
    border-radius: 20vw;
}
div.calendar_ele
{
   flex-direction: column;
   height: 90%;
   padding: 2% 2%;
   border-radius: 10px;
   border:2px solid #161616;
}
div.calendar_ele.calendar_ele_active
{
    border:2px solid #FA4616;
}


/* LEAGUE PAGE CSS */

div.loadingAnimation
{
    margin:auto;
    width: 6vw;
    height: auto;
    padding-top:15vw;
    padding-bottom:15vw;
}
div.loadingAnimation img 
{
    width: 100%;
    height: auto;
}
section.LSC_container
{
  
 
    padding-top: 9%;
    padding-bottom: 4%;
    min-height: 145vw;
}
div.LSC_parent
{
    justify-content: space-between;
    margin-bottom:5%;
}
div.LSC_smallCol_grid
{
  
    width:21.2%;

}
div.LSC_BigCol_grid
{
    width:50%;

    min-height: 200px;
}
div.lls_info_parent
{
    width:70%;
    margin-left:auto;
    margin-right:auto;
}
div.league_logo_container
{
    width: 82%;
    background-color: #fff;
    padding: 7% 9%;
    border-radius: 5vw;

}
div.league_logo_container img 
{
    width: 45%;
    height: auto;
    margin-left: 27.5%;
}
div.league_name
{
    text-align: center;
    margin-top: 4%;
}

div.LSC_smallCol_grid div.LT_league_standings
{
    width: 100%;
    margin-top:9%;
}

div.LSC_smallCol_grid div.LT_fixtures_results_tabs_parent
{
    width: 100%;
}
div.POTW_parent
{
    border-radius: 18px;
}
div.POTW_container
{
    width: 50%;
}
div.POTW_ele
{
    width:100%;
    background-color:#B8B8B8;
    aspect-ratio: 600/800;
    
}
div.POTW_bestBatsman
{
    
   
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center top;

}
div.POTW_bestBowler
{
    
  
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
}
div.NewLeague_pag_icon_container
{
    position:absolute;
    margin-top:8%;
    width: 15.8%;

}
.flex 
{
    display:flex;

}
div.NewLeague_pag_icon
{
    
   
    width:1vw;
    margin-left: auto;
    margin-right:0%;
    cursor:pointer 
   
}
div.NewLeague_pag_icon.prev_icon
{
    transform: rotate(180deg);
    margin-right: auto;
    margin-left: 0%;
    margin-bottom:0.5%;
    
}
div.POTW_player_title
{
    background-color: black;
    width: 100%;
    padding:0.4vw 0vw;
    text-align: center;
}
div.POTW_text_overlay
{
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;  
    background-color: black;     
    text-align: center;
    padding:1vw 0vw;
}

div.POTW_bestBatsman div.POTW_text_overlay
{   
    text-align: left;
    padding: 7% 0% 7% 7%;
    border-radius: 14px 0px 0px 14px;
}
div.POTW_bestBowler div.POTW_text_overlay
{
    text-align: right;
    margin-left:auto;
    padding:7% 7% 7% 0%;
    border-radius: 0px 14px 14px 0px;
}
.POTW_title  
{
    margin-bottom:5%;
}
.POTW_score
{
    text-decoration: underline;
}
.POTW_score_text
{
    margin-bottom:5%;
}

section.LH_container
{
    margin-top:12%;
}
div.tabList_parent .react-tabs__tab {
 
    background: none;
    border: unset;
    bottom: unset;
    padding: 1.8% 0%;
    width: 25%;
    text-align: center;
}
div.tabList_parent .react-tabs__tab--selected
{
    color:#FA4616;
}

div.tabList_parent .react-tabs__tab-list
{
    border: none;
    background-color: #181818;
    border-radius: 18px 18px 0px 0px;
    margin: 0;
}

div.tabList_parent .react-tabs__tab:focus:after
{
    height: 0px;
}

section.TPG_parent
{
    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    row-gap: 1.8vw;
    column-gap: 1.8vw;
    padding:2% 2%;
    background-color: #414141;
    border-radius: 0px 0px 18px 18px;
}
div.SC_ele 
{

}
div.SC_ele_player_image
{
    width: 50%;
    background-color: #9D9898;
    border-radius: 18px 0px 0px 18px;
}
div.SC_ele_player_image img 
{
    width: 80%;
    height: auto;
    margin-left: 10%;
}
div.SC_ele_player_info
{
    width:50%;
    background-color: #181818;
    border-radius: 0px 18px 18px 0px;
    flex-direction: column;
    text-align:center;
}
div.SCE_playerInfo_cat
{
    margin-top: auto;
}
div.SCE_playerInfo_main
{
    margin-bottom: auto;
}
div.LS_parent
{
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    align-items: start;
    row-gap: 1.8vw;
    column-gap: 1.8vw;
    
    background-color: #414141;
    min-height: 20vw;
    border-radius: 0px 0px 18px 18px;
}
div.LS_parent.react-tabs__tab-panel.react-tabs__tab-panel--selected
{
    min-height: 19.8vw;
    padding:2% 2%;
    
}
div.LS_parent.react-tabs__tab-panel
{
    min-height: unset;
}
div.LS_parent div.creds_ele
{
    padding:18% 5%;
    height: -webkit-fill-available;
}
div.NZ_title
{
    background-color:#181818;
    padding:2% 2% 2% 4%;
    border-radius:18px 18px 0px 0px;
}
section.NZ_container
{
    padding-top:5%;
    padding-bottom:5%;
}
div.NZ_NT_tabList_parent
{
    width: 45.7%;
    padding-left: 4%;
    background-color: #181818;
    padding-right: 50.3%;
}
div.NZ_NT_tabList_parent .react-tabs__tab {
 
    background: none;
    border: unset;
    bottom: unset;
    padding: 1.8% 0%;
    width: 25%;
    text-align: left;
}
div.NZ_NT_tabList_parent .react-tabs__tab--selected
{
    color:#FA4616;
}

div.NZ_NT_tabList_parent .react-tabs__tab-list
{
    border: none;
    background-color: #181818;
   
    margin: 0;
}

div.NZ_NT_tabList_parent .react-tabs__tab:focus:after
{
    height: 0px;
}
div.NZNT_table table 
{
    width:100%;
 
    background-color: #414141;
    border-radius: 0px 0px 18px 18px;
    padding-bottom: 3%;
    
}
div.NZNT_table table thead th 
{
    
    padding: 1% 0;
    border-bottom:1.8px solid #272727;
}
div.NZNT_table table thead th.border_th
{
    border-left:1.8px solid #272727;
    
} 
div.NZNT_table table tbody td 
{
    
    padding: 1% 0;
    text-align: center;
    border-bottom:1.8px solid #272727;
}


/* GROUNDS PAGE CSS */
section.GS_container
{
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
}
div.OP_container
{
    
    padding-left: 5%;
    padding-right: 1%;
    width: 26%;
    background-color: rgba(0,0,0,0.5);
    z-index: 8;
    position: relative;
}
div.OP_parent
{
    min-height: 85vh;
    padding-top: 15vh;
    padding-left: 0%;
}
div.OP_title h2 
{
    white-space: nowrap;
}
div.OP_pag_bar_ele 
{
    width:12%;
    text-align: center;
    cursor: pointer;
}
div.pag_bar_ele_image 
{
    width:80%;
    margin-left:auto;
    margin-right:auto;
}
div.pag_bar_ele_image img 
{
    width:100%;
    height:auto;
}
div.OP_pag_bar
{
    padding-top:2%;
    padding-bottom:2%;
}
div.prev_btn
{
    margin-left:auto;
    margin-right:4%;
}
div.prev_btn div.pag_bar_ele_image img 
{
    transform:rotate(180deg);
}
div.OP_loc_container
{
    width:100%;
}
div.OP_loc_container p 
{
    text-decoration: underline;
    padding-right: 0%;
    width: 48%;
    
}
div.OP_loc_ico
{
    width:11%;
    margin-right:4%;

}
div.OPIE_ele 
{
    padding:2% 0%;
    border-bottom:2px solid #626262;
}
div.OP_info_container
{
    padding:4% 0%;
}
div.OP_loc_ico img 
{
    width:100%;
    height:auto;
}
div.OPIE_ele_text
{
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}
div.OPIE_ele_num
{
    margin-left:auto;

    margin-top: auto;
    margin-bottom: auto;
}


section.CSBB_container
{
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
}
div.CSBB_overlayText
{
    padding-top: 20%;
    text-align: center;
    color:#E3E3E3;
}
div.CSBB_title
{
    text-decoration: underline;
    margin-bottom:1%;
}
div.CSBB_comingsoon_copy 
{
    margin-top:2%;
}

div.footer_socialPanel div.social_media_ele
{
    width:15%;
    margin-top: auto;
    margin-bottom: auto;
}
div.footer_socialPanel div.social_media_ele img 
{
    width:100%;
    height:auto;
}
div.footer_socialPanel div.social_media_panel
{
    display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      -webkit-box-direction: normal;
      -moz-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      width: 18%;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2%;
    padding-bottom: 2%;
}

section.join_us_container
{
    background-image: url('../public/images/join_usBg.png');
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% 1%;
}
div.join_us_parent
{
    padding-top:9%;
    padding-bottom:4%;
}
div.join_us_form
{
    padding:2%;
    width:40%;
    background-color:rgba(255,255,255,0.5);
    margin-left:auto;
    border-radius: 18px;
}
div.jUF_title
{
    text-align: center;
    margin-bottom: 2%;
}
div.jUF_copy
{
    text-align: center;
    margin-bottom: 2%;
}
div.fullWidth_inpuTag
{
    width:100%;
    margin-bottom: 2%;
}
div.fullWidth_inpuTag input 
{
    width:100%;
}
div.fifty_fifty_inputTag
{
    justify-content: space-between;
    margin-bottom:2%;
}
div.fifty_fifty_inputTag div.inputTag_holder
{ 
    width:45%;
}
div.fifty_fifty_inputTag input
{
    width:100%;
}
div.fullWidth_inpuTag textarea
{
    width:100%;
    min-height: 8vw;
}
div.inputTag_holder input 
{
    color: #000;
    padding: 0.8vw 8%;
    border-radius: 20vw;
    border: none;
    width: 84%;
}
div.inputTag_holder textarea 
{
    color: #000;
    padding: 2% 5%;
    border-radius: 18px;
    border: none;
    width: 90%;
    max-width: 90%;
    min-width: 90%;
    max-height: 15vw;
}
div.inputTag_holder input:focus
{
    border: none;
}
div.inputTag_holder input:focus-visible
{
    border: none;
    outline: none;
}
div.form_submitButton input
{
    text-align: center;
    border: 1.8px solid #FA4616;
    background-color: #FA4616;
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    color: #E3E3E3;
    border-radius: 20vw;
    padding: 1% 2%;
    transition: all ease 0.8s;
    cursor:pointer;
}
div.LSC_static_columnOne
{
    width:20%;
    
    min-height:10vw;
}
div.LSC_static_columnTwo
{
    width:70%;
    min-height:10vw;
    flex-direction: column;
   
}
div.league_logo_ele
{
    width: 66%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: #E3E3E3;
    padding-top: 12%;
    padding-bottom: 15%;
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 16vw;
}
div.newleague_logo_ele
{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: #E3E3E3;
    padding-top: 12%;
    padding-bottom: 15%;
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 16vw;    
}

div.series_name
{
    padding:0% 10%;
}
div.LL_Slider_prev img
{
    transform: rotate(180deg);
}
div.LL_Slider_pagIco
{
    width:12%;

}
div.LL_Slider_pagIco img 
{
    width:100%;
    height:auto;
    margin-top:auto;
    margin-bottom:auto;
    cursor:pointer;
}
div.leagueStatic_name
{
    padding:0% 15%;
}
div.league_logo
{
    width: 60%;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: auto;
    margin-right: auto;
}
div.league_logo img 
{
    width:100%;
    height:auto;
}

div.PSC_header
{
    background-color: #181818;
    border-radius: 18px 18px 0px 0px;
    padding:1.6% 2.8%;
}
div.PSC_title
{
    margin-right:5%;
}

div.PSC_body
{
    background-color:#414141;
    padding: 4% 2.8%;
    border-radius: 0px 0px 18px 18px;
}
div.PSC_stats_grid
{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 0;
    column-gap: 0vw;
}
div.PSC_stats_ele
{
    text-align: center;
}
div.PSC_stats_ele p 
{
    margin:5% 0;
}
div.LSC_moredetails img 
{
    width:5%;
    height: auto;
    margin-right:1%;
}
div.LSC_moredetails 
{
    margin-top:auto;
}
div.LSC_moredetails p 
{
    margin-top:auto;
    margin-bottom:auto;
}


section.LSRS_container
{
    overflow: hidden;
}
div.LSRS_ele
{
    width: 47%;
    margin-right: 3.2%;
   
}
div.LSRS_ele div.MRE_ele 
{
    margin-bottom:5%;
}
div.LSRS_title
{
    background-color: #181818;
    padding: 1.6% 2.8%;
    border-radius: 18px;
    margin-bottom:4%;
}

section.LSRS_container .flickity-page-dots
{
    list-style: none;
    display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      -webkit-box-direction: normal;
      -moz-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      justify-content: space-between;
      width: 50%;
      margin-left:auto;
      margin-right:auto;
      padding: 0;
      margin-top: 4%;

}
section.LSRS_container .flickity-page-dots li.dot
{
    width: 45px;
    height: 10px;
    background-color: #B5B5B5;
    border-radius: 20vw;
    transtion:all ease 0.6s;
    cursor: pointer;
 
}
section.LSRS_container .flickity-page-dots li.dot.is-selected
{
    background-color: #fff;
}
section.LSRS_container div.MRE_ele div.match_date_info p 
{
    font-size: 1.3rem;
    line-height: 2rem;
}
section.LSRS_container div.MRE_ele div.match_date_info p span
{
    font-size: 2rem;
    line-height: 2rem;
}
div.bg_slider
{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 7;
}
div.bg_slider img 
{
    position: absolute;
    width:100%;
    height:100%;
    z-index: 7;
    transition:all ease 1.2s;
}
img.transparent_bg
{
    opacity:0;
}
div.mobile_navigation
{
    display: none;
}
div.mobile_nav_ele
{
    display:none;
}
div.my_parrallax_banner
{
    aspect-ratio: 2.4 / 1;
    min-height: 36vw;
}
div.joinUs_bgParrallax
{
    aspect-ratio: 3/1;
    min-height: 30vw;
}
div.parrallax_logo
{
    width:18%;
}
div.POFT_container
{
    width: 5%;
    position: absolute;
    left: 47.5%;
    margin-top:-2.5%;
}
div.POFT_container  img 
{
    width:100%;
    height: auto;
}



.dropdown-content {
    display: none;
    position: absolute;
    background-color: unset;
    min-width: 160px;
    
    z-index: 1;
    transition:all ease 0.8s;
  }
  .dropdown-content a {
    color: #E3E3E3;
    padding: 1.2vw 0%;
    text-decoration: none;
    display: block;
    transition: all ease 0.8s;
    
  }
  .dropdown-content a:hover {background-color: unset;color: #FA4616!important;}
  header.main_header.active_header .dropdown-content
  {
    background-color: #fff;
    color:#000;

    padding-top: 1%;
  }
  header.main_header.active_header .dropdown-content a
  {
    padding-left:5%;
  }
  header.main_header.active_header .dropdown-content a:hover{ background-color: #fff; color:#FA4616 !important;}
    .dropdown:hover .dropdown-content {display: block;}

    .dropdown:hover .dropbtn {background-color: #3e8e41;}

div.LSC_moredetails_parent
{
    margin-top:auto;
}
div.homeGround_logo
{
    width:8%;
    height:auto;
    text-align: center;
    position: absolute;
    right:2%;
    top:70%;
    z-index: 9;
    padding: 1% 3%;
    background-color: white;
    border-radius: 18px;
}
div.homeGround_logo img 
{
    width:100%;
    height:auto;
}
.hidden_ele
{
    display: none;
}
.success_msg
{
    position: fixed;
    left: 5%;
    bottom: 5%;
}

.SM_container
{
    width: 52%;
    background-color: #fff;
    border-radius: 20vw;
    padding: 1.8% 2.8%;
    justify-content: space-around;
    border:1.8px solid #68BF7B;
    transition:all ease 0.6s;
    opacity: 1;
}
.SM_container.hidden_SC
{
    opacity:0;
}
div.SM_ico
{
    width:20%;
    margin-left:auto;
}
div.SM_msg
{
    width:75%;
    margin-top:auto;
    margin-bottom: auto;
    margin-left:5%;
    margin-right:auto;
    text-align: center;
}
div.SM_ico img 
{
    width:100%;
    height: auto;
}
div.ISLTC_parent
{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    row-gap: 4vw;
    column-gap: 8vw;
    padding-top:5%;
    padding-bottom:5%;
}
div.instaEle
{
    width:100%;
    height:auto;
    background-color:#F1F3F6;
    border-radius: 18px;
}
div.instaImage
{
    width:100%;
    height: auto;
}
div.instaCaption
{
    padding:4% 4%;
}
div.instaImage img 
{
    width: 100%;
    height:auto;
    border-radius: 18px 18px 0px 0px;
}


section.allPlayersPanel_header
{
    min-height: 100vh;
    padding-top: 9%;
    padding-bottom: 4%;
}
.allpp_title
{
    color:white;
    margin-top:4vw;
}
div.players_panel_container
{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1.5vw;
    margin-top: 2vw;
}
div.appTitle
{
    margin-top:4vw;
}
div.playerCardEle
{
    width:100%;
    aspect-ratio: 248/388;
    background-image:url("../public/images/bg-patterns/prism_bg_one.png");
    background-size:cover;
    background-repeat: no-repeat;
    display:flex;
    flex-direction: column;
}
div.playerPlayingStyle
{
    margin:0 auto;
    width: 1.5vw;
    padding:0.5vw 0vw;
}

div.playerPlayingStyle img 
{
    width: 100%;
    height: auto;

}

div.playerImage
{
    width: 85%;
   
    margin: 0vw 7.5%;
    background-image:url("../public/images/bg-patterns/header_bg_one.svg");
    background-size: cover;
    background-repeat: no-repeat;
    aspect-ratio: 220/242;
    
}
div.playerImageBg  
{
    width: 100%;
    height: 100%;
    clip-path: polygon(
    50% 0%, 
    100% 7.5%, 
    100% 92.5%, 
    50% 100%, 
    0% 92.5%, 
    0% 7.5%
  );
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
div.playerinfo
{
    width: 85%;
   
    margin: 0vw 7.5%;
    background-image:url("../public/images/bg-patterns/body_bg_one.svg");
    background-size: cover;
    background-repeat: no-repeat;
    aspect-ratio: 224/106;
    display: flex;
    flex-direction: column;
}
div.playerinfo h2 
{
    margin-top: auto;
    text-align: center;
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
}
div.playerinfo p
{
    margin-bottom: auto;
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-style: normal;
}
div.pagination_home_controls
{
    display: flex;
    position: absolute;
    width: 21%;
    margin-top:13%;
}
div.pagination_home_controls img 
{
    width: 2vw;
    height: auto;
    cursor: pointer;
}

@media only screen and (max-width: 1024px)
{
    div.pagination_home_controls {

        width: 70%;
        margin-top: 20%;
        margin-left: 7.5%;
    }
    div.pagination_home_controls img {
        width: 10vw;
    }
}
div.pagination_home_controls img.phm_left
{
    transform: rotate(180deg);
}

div.pagination_home_controls img.phm_right
{
    margin-left:auto;
    
}
div.credsContainer
{
    margin:1.5vw 0%;
}
div.credsContainer_grph
{
    width:8.5%;
    margin-right:2%;

}
div.credsContainer_grph p 
{
    text-align: center;
}
svg.credsContainer_ico
{
    width: 100%;
    height:auto;
    margin-bottom: 0.3vw;
}
p.credsContainer_para
{
    margin-top:auto;
    margin-bottom: auto;
}

@media only screen and (max-width: 1024px)
{
    div.mobile_nav_ele
    {
        display:block;
    }
    div.rightBanner
    {
        display:none;
    }
    div.nav_container 
    {
        display:none;
    }
    div.millhouse_logo 
    {
        display:none
    }
    .main_header .site_logo {
        width: 15%;
    }
    .nav_burger {
        width: 100%;
        margin-top: auto;
        transition: 0.5s ease-in-out;
        margin-bottom: 4.1%;
    }
    div.mobile_nav_ele
    {
        width: 8%;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
    }
    .nav_burger span {
        display: block;
        height: 2px;
        width: 100%;
        background: #FA4616;
        margin-bottom: 25%;
        transition: 0.4s ease-in-out;
    }
    .nav_burger.nav-open span:nth-child(1) {
        transform: rotate(-45deg) translate(0px, 0px);
        transform-origin: 75% 0%;
        transition: transform 0.3s cubic-bezier(0.77,0.2,0.05,1.0);
    }
    .nav_burger.nav-open span:nth-child(2) {
        opacity: 0;
        transition: opacity 0.3s cubic-bezier(0.77,0.2,0.05,1.0);
    }
    .nav_burger.nav-open span:nth-child(3) {
        transform: rotate(45deg) translate(-3px, -4px);
        transform-origin: 90% 0%;
        transition: transform 0.3s cubic-bezier(0.77,0.2,0.05,1.0);
    }
    header.main_header
    {
        background-color:#fff !important;
    }
    div.mobile_navigation
    {
        min-height: 70vh;
        width: 45%;
        top: 7%;
        margin-left: auto;
        position: absolute;
        right: 0;
        background-color: #fff;
        display: none;
        transition: all ease 0.8s;
        padding: 4% 5% 4% 6%;
        position: fixed;
        box-shadow: 0 2px 9px 0 rgb(0 0 0 / 9%);
        z-index: 9;
    
    }
    div.mobile_navigation.active_nav
    {
        display:block;
        z-index: 999;
    }
    div.players_panel_container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    div.mob_nav_ele
    {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        padding: 6% 0%;
        border-bottom: 1.8px solid #FA4616;
       
    }
    div.mob_nav_ele_btn
    {
        padding: 6% 0%;
    }
    div.mob_nav_ele_btn button.nav_ele_btn
    {
        color: #fff;
        background: #FA4616;
        border-radius: 20vw;
        padding: 4% 16%;
        margin-left: auto;
        width: 100%;
    }
    div.mob_nav_ele_btn button.nav_ele_btn a 
    {
        color:#fff;
    }
    div.mob_nav_ele img 
    {
        width:100%;
        height:auto;
    }
    div.mob_nav_parent
    {
        width:100%;
        height:auto;
    }
    div.hero_content_block {
       
        width: 100%;
        margin-top: auto;
    }
    div.MDE_parent
    {
        flex-direction: column;
    }
    div.MDE_parent div.MDE_container {
        width: 87%;
        margin-bottom: 12%;
        margin-left: auto;
        margin-right: auto;
        margin-top: -5%;
    }
    div.view_all_btn button {
        padding: 1.8% 8% 1.8% 8%;
        background-color: #FA4616;
        color:#fff;
    }
    div.LT_parent
    {
        padding-top:5%;
    }
    div.LT_title {
        margin-bottom: 8%;
    }
    div.LT_container
    {
        flex-direction: column;
        padding: 0% 2% 6% 2%;
    }
    div.LT_league_logo {
        width: 40%;
        margin-left: auto;
        margin-right: auto;
        padding: 11% 5% 45% 5%;

    }
    div.LT_league_info {
        width: 100%;
        flex-direction: row;
    }
    div.LT_container div.LT_league_standings {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2%;
    }
    div.LT_container div.LT_fixtures_results_tabs_parent {
        width: 90%;
        margin-top: 15%;
        margin-left: auto;
        margin-right: auto;
    }
    div.my_parrallax_banner
    {
        aspect-ratio: 1.5/ 3;
        min-height: 110vh;
    }
    div.NMC_title {
        padding: 0% 5%;
        margin-top: auto;
        margin-bottom: 8%;
    }
    div.NMC_container div.counter {
        width: 80%;
        flex-wrap:wrap;
    }
    div.counter_ele {
        width: 50%;
        margin-left: auto;
        text-align: center;
        color: #E3E3E3;
        margin-right: auto;
        margin-bottom: 7%;
    }
    div.NMC_container div.common_buttonHolder button {
        padding: 1.8% 7% 1.8% 7%;
        background-color: #FA4616;
        color:white;
    }
    div.slider_extraInfo_container
    {
        flex-direction: column;
    }
    div.bg_eagle_logo {
        width: 80%;
        margin-left: 5%;
        position: absolute;
        aspect-ratio: 4 / 4;
        z-index: 1;
    }
        div.player_content {
        width: 50%;
        padding-bottom: 20%;
    
        z-index: 99;
        width: 100%;
        text-align: center;
        padding-bottom: 10%;
    }
    div.slider_container {
        width: 100%;
        padding-top: 1%;
        z-index: 8;
    }
    div.POTW_container {
        width: 100%;
    }
    div.slider_ele.is-selected img {
        width: 200%;
        margin-top: -111%;
        margin-left: 0%;
    }
    div.slider_ele {
        width: 45%;
        margin-right: 80%;
    }
    div.slider_paginationBar {
        width: 12%;

        left:unset;
        right: 5%;

        margin-top: -33%;
    }
    div.slider_pag_ele {
        width: 100%;
    }
    div.player_creds
    {
       
    }
    div.player_match_creds {
        width: 90%;
        padding: 5% 5% 5% 5%;
    }
    div.player_bio
    {
        padding:2% 5% 5% 5%;
    }
    div.joinUs_bgParrallax
    {
        aspect-ratio: 2.2/3;
        min-height: 77vh;
    }
    div.logo_container.logo_center.parrallax_logo
    {
        width:60%;
        margin-bottom: 9%;
    }
    div.LG_parent2 {
        padding-top: 8%;
        padding-bottom: 8%;
    }
    div.LG_parent {

        column-gap: 5vw;
        padding-top: 8%;
        padding-bottom: 8%;
    }
    section.footer_parent {

        padding-top: 4%;
        padding-bottom: 4%;

    }
    div.footer_socialPanel div.social_media_panel {
        
        width: 60%;
        padding-top: 5%;
    padding-bottom: 5%;
    }
    div.hero-banner
    {
       
    }
    div.OP_container {
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
        max-height: 100vh;
        overflow: scroll;
    }
    div.OP_title h2
    {
        white-space: unset;
    }
    div.mobGround_viewer
    {
        width:100%;
        height:auto;
    }
    div.mobGround_viewer img 
    {
        width:110%;
        height:auto;
        margin-left:-5%;
    }
    div.CSBB_overlayText {
        padding-top: 50%;
    }
    div.CSBB_title {

        margin-bottom: 3%;
    }
    div.CSBB_bodyText
    {
        margin-bottom:2%;
    }
    div.LSC_parent
    {
        flex-direction: column;
    }
    section.LSC_container {

        padding-top: 20%;
    }
    div.LSC_static_columnOne {
        width: 100%;

        margin-left: auto;
        margin-right: auto;
    }
    div.leagueLogo_slider 
    {
        width:75%;
        margin-left: auto;
        margin-right:auto;
    }
    div.series_name {
        padding: 0% 19%;
    }
    div.league_logo_ele
    {
        padding-top:14%;
        padding-bottom:35%;
    }
    div.newleague_logo_ele 
    {
        padding-top: 14%;
        padding-bottom: 35%;
        aspect-ratio: 4 / 3;
    }
    div.LSC_static_columnTwo {
        width: 100%;
    }
    div.PSC_stats_grid
    {
        grid-template-columns: repeat(3, 1fr);
    }
    div.LSC_moredetails {
        margin-top: 5%;
        margin-bottom: 5%;
    }
    div.NewLeague_pag_icon_container {

        width: 70%;
        margin-left: 10%;
        margin-top: 37%;

    }
    section.posStats_container
    {
        
    }
    div.LSC_static_columnOne div.LT_league_standings
    {
        width: 100%;
        margin-left:auto;
        margin-right:auto;
    }
    section.LSRS_container
    {
        margin-top:10%;
    }
    div.LSRS_ele {
        width: 100%;
    }
    section.LSRS_container .flickity-page-dots {
      
        width: 100%;
    }
    div.LSC_smallCol_grid
    {
        width:100%;
    }
    div.LSC_BigCol_grid {
        width:100%;
    }
    div.POTW_parent
    {
        margin-top: 30%;
        flex-direction: column;
    }
    div.POFT_container {
        width: 20%;
        position: absolute;
        left: 40%;
        margin-top: -23%;
    }
    div.POTW_ele {
        width: 100%;
        border-radius: 18px;
        margin-bottom:10%;
    }
    div.tabList_parent .react-tabs__tab {

        padding: 3.8% 0%;
    }
    div.LSC_smallCol_grid div.LT_fixtures_results_tabs_parent {
        width: 100%;
        margin-top: 18%;
    }
    section.LH_container {
        margin-top: 8%;
    }
    div.LS_parent
    {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 4vw;
        column-gap: 4vw;
    }
    section.TPG_parent {

        grid-template-columns: repeat(1, 1fr);
    }
    div.SC_ele {
        margin-top:5%;
        margin-bottom: 6%;
    }
    div.LT_fixtures_results_tabs_parent .react-tabs__tab-list {
       
        margin: 0 0 5%;
    }
    div.MXE_ele {

        margin-bottom: 3%;
    }
    div.NZNT_table table {
        width: 201%;
    }
    .react-tabs__tab-panel--selected {
        overflow-x: scroll;
    }
    div.join_us_form {
        padding: 4%;
    width: 82%;
    margin: 14% 5%;
    }
    div.fullWidth_inpuTag textarea {
        
        min-height: 28vw;
    }
    section.join_us_container
    {
        background-image: url('../public/images/join_usMobBg.jpg');
    }
    div.UMP_parent_grid 
    {
        flex-direction: column;
    }
    div.UMP_match_ele {
        width: 70%;
     
        margin-left: auto;
        margin-right: auto;
    }
    div.UMP_timer {
        width: 100%;
        margin-top:8%;
    }
    div.counter_num {
        font-size: 3.2rem;
        line-height: 3.2rem;
    }
    button.react-calendar__navigation__arrow {

        font-size: 1.8rem;
        line-height: 1.8rem;
    }
    button.react-calendar__navigation__label {
 
        font-size: 1.6rem;
        line-height: 1.6rem;
    }div.react-calendar__navigation 
    {
        width: 96%;
        padding: 4% 2%;

        border-radius: unset;
        margin-bottom: 0%;
    }
    div.react-calendar__month-view__weekdays
    {
        display: flex!important;
        background-color: #000;
        color:#E3E3E3;
        padding: 4% 0%;
        text-align: center;
        font-family: 'Oswald', sans-serif;
        font-weight:500;
        font-style:normal;
        border-bottom: 1px solid;
        border-top: 1px solid;
    }
    div.react-calendar__month-view__weekdays div.react-calendar__month-view__weekdays__weekday abbr
    {
        text-decoration: unset;
    }
    div.calendar_ele_date {
        text-align: center;
        width: 30px;
        height: 26px;
        border-radius: 20vw;
       
        padding-top: 4px;
        margin-left: auto;
        margin-right: auto;
        background-size: contain;
    background-repeat: no-repeat;
    }
    button.customCale_ele
    {
        background-color: unset;
        border: unset;
    }
    div.calendar_ele
    {
        border:unset;
        margin-bottom: 7%;
    margin-top: 7%;
    }
    div.calendar_ele.calendar_ele_active
    {
        border:unset;
    }
    div.calendar_ele.calendar_ele_active div.calendar_ele_date
    {
        background-color: #FA4616;
        
    }
    div.calendar_ele.calendar_ele_active div.calendar_ele_date p 
    {
        
    }
    div.calendar_ele.foundMatchActive div.calendar_ele_date p 
    {
        display: none;
    }
    div.react-calendar__month-view__days
    {
        background-color: #000;
        padding-bottom:3%;
        border-radius: 0px 0px 18px 18px;
    }
    div.match_showCase 
    {
        padding: 2%;
        background-color: rgb(22, 22, 22);
        border-radius: 18px 18px 0px 0px;
    }
    div.match_showCase div.MDE_container
    {
        color: #E3E3E3;
        width: 76%;
        padding: 2%;
        margin-left: auto;
        margin-right: auto;
    }
    div.no_game_container
    {
        padding: 24% 0%;
        text-align: center;
    }
    div.calendar_holder {
        padding-bottom: 10%;
    }
    div.creds_ele {

        min-width: 16vw;
    }
    section.UMP_container {

        padding-top: 15%;
    }
    div.NZ_NT_tabList_parent {
        width: 90%;
        padding-left: 3%;

        padding-right: 7%;
    }
    div.NZ_NT_tabList_parent .react-tabs__tab {

        padding: 3.2% 0%;
    }
    div.NZ_title {

        padding: 2% 2% 2% 3%;
    }
    div.LG_parent2 div.LG_ele {
        width: 45%;
    }
    div.homeGround_logo
    {
        display: none;
    }
    div.ISLTC_parent
    {
        display:grid;
        grid-template-columns: repeat(1, 1fr);
    }
}