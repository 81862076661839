@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Yantramanav:wght@100;300;400;500;700;900&display=swap');

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('../public/images/backgroundBg.jpg');

  background-size: 100%;
  background-repeat:repeat-y;
}
html 
{
  overflow-x: hidden;
}
h1, h2, h3, h4,h5,h6,p 
{
  margin:0;
}



.base_margins
{
  margin-left:5%;
  margin-right:5%;
}
.base_paddings
{
  padding-left:5%;
  padding-right:5%;
}
.max_content
{
  max-width:1440px;
}
.center_aligned
{
  margin-left:auto;
  margin-right:auto;
}

.flex_grid
{
    display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      -webkit-box-direction: normal;
      -moz-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
}

.no_underline
{
  text-decoration: unset;
}

.no_href
{
  text-decoration: unset;
  color:inherit;
}

/* COLOR PALLATE DEFINED BELOW */
.white_color
{
  color:#E3E3E3;
}
.brand_orange 
{
  color:#FA4616;
}
.grey_text
{
  color:#C0C0C0
}
.light_grey 
{
  color:#F0F0F0;
}
.brand_blue
{
  color:#0066B3;
}
.content_black
{
  color:#000;
}
/* TYPOGRAPHY DEFINED BELOW */
.roboto-condensed-light
{
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  font-style: normal;
}
.roboto-condensed-light-italic
{
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  font-style: italic;
}
.roboto-condensed-regular 
{
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-style: normal;
}
.roboto-condensed-regular-italic
{
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-style: italic;
}
.roboto-condensed-med
{
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-condensed-bold
{
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  font-style: normal;
}
.roboto-condensed-bold-italic 
{
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.oswald-extra-light
{
  font-family: 'Oswald', sans-serif;
  font-weight:200;
  font-style:normal;
}
.oswald-light 
{
  font-family: 'Oswald', sans-serif;
  font-weight:300;
  font-style:normal;
}
.oswald-regular 
{
  font-family: 'Oswald', sans-serif;
  font-weight:400;
  font-style:normal;
}
.oswald-med
{
  font-family: 'Oswald', sans-serif;
  font-weight:500;
  font-style:normal;
}
.oswald-semi-bold
{
  font-family: 'Oswald', sans-serif;
  font-weight:600;
  font-style:normal;
}
.oswald-bold
{
  font-family: 'Oswald', sans-serif;
  font-weight:700;
  font-style:normal;
}

/* FONT SIZE DEFINED BELOW */
.p6
{
  font-size: 0.8rem;
  line-height: 1.2rem;
}
.p5 
{
  font-size:0.9rem;
  line-height: 1.3rem;
}
.p4
{
  font-size:1rem;
  line-height: 1.4rem;
}
.p3 
{
  font-size:1.13rem;
  line-height: 1.5rem;
}
.p2 
{
  font-size:1.31rem;
  line-height: 1.8rem;
}
.p1
{
  font-size:1.5rem;
  line-height: 2rem;
}
.h4 
{
  font-size:2.4rem;
  line-height: 2.8rem;
}
.h3 
{
  font-size:3rem;
  line-height: 3.6rem;
}
.h2 
{
  font-size:3.4rem;
  line-height:4rem;
}
.h1 
{
  font-size:4.25rem;
  line-height: 4.8rem;
}



.desk_only
{
  display:block;
}
.desk_inLine_only
{
  display:inline-block;
}
.mob_only
{
  display:none;
}
@media only screen and (max-width: 1024px)
{
  .desk_inLine_only
  {
    display:none;
  }
  .desk_only
  {
    display:none;
  }
  .mob_only
  {
    display:block;
  }
  /* FONT SIZE DEFINED BELOW */
  .p6
  {
    font-size: 0.7rem;
    line-height: 1.2rem;
  }
  .p5 
  {
    font-size:0.8rem;
    line-height: 1.3rem;
  }
  .p4
  {
    font-size:0.9rem;
    line-height: 1.4rem;
  }
  .p3 
  {
    font-size:1.03rem;
    line-height: 1.5rem;
  }
  .p2 
  {
    font-size:1.21rem;
    line-height: 1.8rem;
  }
  .p1
  {
    font-size:1.4rem;
    line-height: 2rem;
  }
  .h4 
  {
    font-size:2.2rem;
    line-height: 2.8rem;
  }
  .h3 
  {
    font-size:2.8rem;
    line-height: 3.6rem;
  }
  .h2 
  {
    font-size:3.1rem;
    line-height:4rem;
  }
  .h1 
  {
    font-size:3.8rem;
    line-height: 4.8rem;
  }
}
